.openBtn {
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
  margin-right: 20px;
}

.closeBtn {
  position: relative;
  width: 15px;
  height: 15px;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
}

.closeBtn:hover {
  opacity: 1;
}

.closeBtn:before,
.closeBtn:after {
  position: absolute;
  top: 0;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: var(--white);
}

.closeBtn:before {
  transform: rotate(45deg);
}

.closeBtn:after {
  transform: rotate(-45deg);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 15px;
}

.navItem {
  display: block;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--grey-300);
}

.activeNav {
  color: var(--white);
}

.logo {
  width: 150px;
}

.navigation {
  display: flex;
  gap: 20px;
}

.localizationButton {
  width: 21px;
  height: 21px;
  background-color: none !important;
  cursor: 'pointer';
}